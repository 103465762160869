import Link from "next/link";

import Image from "next/image";
import logo from "@/img/logo.png";
import instagramLogo from "@/img/instagramWhite.svg";
import facebookLogo from "@/img/facebookWhite.svg";
import twitterLogo from "@/img/twitterWhite.svg";
import linkedinLogo from "@/img/linkedinWhite.svg";
import youtubeLogo from "@/img/youtubeWhite.svg";
import tiktokLogo from "@/img/tiktokWhite.svg";
import { FC } from "react";
import useMobileDetect from "hooks/useMobileDetect";

const footerLinks = [
  { name: "Home", href: "/" },
  { name: "About Us", href: "/about-us" },
  { name: "Youdle Nation", href: "https://www.facebook.com/groups/getyoudle/" },
  { name: "Feedback", href: "https://forms.gle/mmFXxZwx71bjnKta8" },
  { name: "News", href: "https://news.youdleit.com/" },
  { name: "Shop", href: "https://youdle.printify.me/products" },
  // { name: "Sell", href: "/sell" },
  // { name: "Watchlist", href: "/watchlist" },
  // { name: "Account", href: "/signin" },
  { name: "Privacy Policy", href: "/privacy-policy" },
  { name: "Terms and Conditions", href: "/privacy-policy" },
];

const Footer = () => {
  return (
    <footer
      className="relative bottom-0 w-full bg-youdleDarkBlue text-black"
      id="footer"
    >
      <FooterContent />
      <div className=" text-center font-thin text-white">
        <p className="m-0 pt-1 text-white">Copyright &copy; Youdle 2024</p>
        <p> All Rights Reserved</p>
      </div>
    </footer>
  );
};

const FooterContent: FC = () => {
  const { isMobile } = useMobileDetect();
  return (
    <>
      {isMobile ? (
        <div className="flex flex-col p-12 pb-6">
          <Image src={logo} alt="Youdle logo" className="h-auto w-8/12" />
          <div className="grid grid-cols-2 pt-12">
            <LinkCol footerLinks={footerLinks.slice(0, 4)} />
            <LinkCol footerLinks={footerLinks.slice(4, 8)} />
          </div>
          <div className="py-10">
            <LinkCol footerLinks={footerLinks.slice(8)} />
          </div>
          <Socials />
        </div>
      ) : (
        <div className="grid grid-cols-5 p-12 pb-6">
          <Image src={logo} alt="Youdle logo" className="h-auto w-8/12" />
          <LinkCol footerLinks={footerLinks.slice(0, 4)} />
          <LinkCol footerLinks={footerLinks.slice(4, 8)} />
          <LinkCol footerLinks={footerLinks.slice(8)} />
          <Socials />
        </div>
      )}
    </>
  );
};

interface LinkColProps {
  footerLinks: { name: string; href: string }[];
}

const LinkCol: FC<LinkColProps> = ({ footerLinks }) => {
  return (
    <div className="flex flex-col text-white">
      {footerLinks.map((link) => (
        <Link href={link.href} key={link.name}>
          {link.name}
        </Link>
      ))}
    </div>
  );
};

const socialLogos = [
  {
    name: "Instagram",
    img: instagramLogo,
    url: "https://instagram.com/getyoudle",
  },
  {
    name: "Facebook",
    img: facebookLogo,
    url: "https://facebook.com/groups/getyoudle",
  },
  { name: "Twitter", img: twitterLogo, url: "https://twitter.com/getyoudle" },
  {
    name: "LinkedIn",
    img: linkedinLogo,
    url: "https://www.linkedin.com/company/youdle/",
  },
  { name: "Youtube", img: youtubeLogo, url: "https://www.youtube.com/@youdle" },
  { name: "TikTok", img: tiktokLogo, url: "https://www.tiktok.com/@getyoudle" },
];

const Socials = () => {
  return (
    <div className=" grid grid-cols-3 xxs:grid-cols-6 md:grid-cols-3 xl:grid-cols-6">
      {socialLogos.map((social) => (
        <Link
          key={social.name}
          href={social.url}
          className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white"
        >
          <Image
            src={social.img}
            alt={social.name}
            width={24}
            height={24}
            className="max-w-6 max-h-6"
          />
        </Link>
      ))}
    </div>
  );
};

export default Footer;
