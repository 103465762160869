import { useEffect, useState } from "react";

const useMobileDetect = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isTabletOrLess, setIsTabletOrLess] = useState(false);
    const [isSmallVertical, setIsSmallVertical] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setIsTabletOrLess(window.innerWidth <= 1023);
            setIsSmallVertical(window.innerHeight <= 720);
        };

        // Call handleResize immediately to set the initial state
        handleResize();

        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs on mount and unmount only

    return { isMobile, isTabletOrLess, isSmallVertical };
};

export default useMobileDetect;
